/**
 * SASS
 */
require('./styles/main.scss');

/**
 * JS
 */
require('./scripts/main.js');
require('./scripts/bulk-scan-tool.js');
