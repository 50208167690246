//import "./slick.js";

(function($) {

	$(document).on('ready', function() {

		// language switcher
		$( ".js-language-toggle" ).click(function() {
			$(this).parent('.language-switcher').toggleClass( 'language-switcher--active' );
		});

		// language switcher
		$( ".js-nav-toggle" ).click(function() {
			$('body').toggleClass( 'active-navigation' );
		});

		// Toggles
		$('.toggle-button').on('click', function() {
			$(this).parent('.toggle').toggleClass('toggle--active');
		});

		// Remove empty paragraphs
		$('p').each(function() {

			var $this = $(this);

			if ( $this.html().replace(/\s|&nbsp;/g, '').length == 0 ) {
				$this.remove();
			}

		});
		
		const urlParams = new URLSearchParams(window.location.search);
		const filterColor = urlParams.get('filter_color');
		if( filterColor ){
			checkSwatches( filterColor );
		}
	});

	function checkSwatches( filterColor = null ){
		
		filterColor = filterColor + "," + filterColorDescs[filterColor];
		const filterColors = filterColor.split(',');

		// Get all available variations (you may need to adjust this selector)
		const variations = $('.variable-items-wrapper .image-variable-item');

		const intervalID = setInterval( function() {
			if( $('.wvs-archive-variations-wrapper').find('.blockUI').length > 0 ){
				// The swatches are still loading
			}else{
				// Iterate through variations
				variations.each(function () {
					const variationColor = $(this).data('value'); // Assuming data attribute contains color value
		
					for( i = 0; i < filterColors.length; i++ ){
		
						// Check if the variation color matches the filter color
						if (filterColors[i] !== "" && variationColor.includes( filterColors[i] )) {
							// Update the main product image with the variation image URL
							$(this).click();
						}
					}
				});
				clearInterval( intervalID );
			}
		}, 300 );
	}

})(jQuery);
