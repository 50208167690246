const scanForm = document.getElementById('scan-add-to-cart')

function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

if (scanForm) {
    const skuInput = scanForm.querySelector('input[name="sku"]')

    if(skuInput) {
    scanForm.addEventListener('submit', (e) => {
        e.preventDefault()
    })

    skuInput.addEventListener('input', debounce((e) => {
        if (e.target.value.length >= 5 && scanForm) {
            skuInput.setAttribute('readonly', true)
            scanForm.classList.add('is-loading')
            scanForm.submit()
        }
    }, 200))

    skuInput.focus()
  }
}